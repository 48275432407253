<template>
    <div class="container">        
        <div class="row row-height mb-4">
            <div class="col-md-5 offset-md-3">
                <div class="card">
					<div class="card-header"><h3 class="card-title">Descargue su comprobante</h3></div>
					<div class="card-body">
                        <p v-if="resultado.codigo_unico==''">No se han encontrado resultados.</p>
                        <div v-else>
                            <div class="row">
                                <div class="col-md-6">
                                    <PdfThumbnail :fileUrl="base_url+'/webserviceserver/comprobante_digital/pdf/'+resultado.codigo_unico" :width="120" :height="200" :target="'_blank'"></PdfThumbnail>
                                    <button :class="['btn', 'mt-2', 'btn-claro']" @click="downloadItem(base_url+'/webserviceserver/comprobante_digital/pdf/'+resultado.codigo_unico, resultado.comprobante+'.pdf')">
                                        <i class="fa fa-download ml-2" aria-hidden="true"></i>
                                        Descargar
                                    </button>
                                </div>
                                
                                <div class="col-md-6">
                                    <a href="javascript:void(0)">
                                        <img src="/img/xml_thumbnails.png" alt="..." style="width:120px; height:202px;" class="img-thumbnail mb-3">
                                    </a>
                                    <button :class="['btn', 'btn-claro']" @click="downloadItem(base_url+'/webserviceserver/comprobante_digital/xml/'+resultado.codigo_unico, resultado.comprobante+'.xml')">
                                        <i class="fa fa-download ml-2" aria-hidden="true"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import PdfThumbnail from '@/components/PdfThumbnail.vue';
    export default {
        name: 'Web',
        computed:{
            ...mapState(['loading','empresa']),
        },
        components: {
            PdfThumbnail,
		},
        created(){
            this.consultarComprobante(this.$route.params.codigo_unico);
        },
        mounted(){
            this.loadObjEmpresa();
            document.title = "Bienvenido a la "+((this.empresa.abreviatura)?this.empresa.abreviatura:"CLARO");
        },
        data(){
            return{
                resultado:{
                    codigo_unico:this.$route.params.codigo_unico,
                    comprobante:""
                },
                base_url: `${window.baseUrl}`,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjEmpresa']),
            consultarComprobante(codigo_unico){
                this.setLoading(true);

                this.$http.get('webserviceserver/verificar_comprobante/'+codigo_unico).then((res)=>{
                    if(res.data.result!=""){
                        this.resultado.codigo_unico = res.data.codigo_unico;
                        this.resultado.comprobante  = res.data.comprobante;
                    }else{
                        this.resultado.codigo_unico = "";
                        this.resultado.comprobante  = "";
                    }
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
        },
    }
</script>
